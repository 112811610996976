import React, { useContext, useState, useRef, ReactNode } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Menu, Modal } from "antd";
import useOnClickOutside from "library/hooks/useOnClickOutside";
import { AuthContext } from "context/AuthProvider";
import { AGENT_PROFILE_PAGE } from "settings/constant";

export default function ProfileMenu({ avatar }) {
  let navigate = useNavigate();
  const { logOut } = useContext(AuthContext);
  const [state, setState] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const user = JSON.parse(localStorage.getItem("profile"));
  const firstNameInitial = user?.first_name ? user.first_name.charAt(0) : "";
  const lastNameInitial = user?.last_name ? user.last_name.charAt(0) : "";
  const handleDropdown = () => {
    setState(!state);
  };
  const closeDropdown = () => {
    setState(false);
  };
  const dropdownRef = useRef(null);
  useOnClickOutside(dropdownRef, () => setState(false));

  function handleLogout() {
    setIsModalVisible(true);
  }
  const handleOk = () => {
    logOut();
    navigate("/", { replace: true });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const menuItems = [
    {
      label: (
        <NavLink
          to={AGENT_PROFILE_PAGE}
          style={{ textTransform: "capitalize" }}
        >
          Dashboard
        </NavLink>
      ),
      key: "dashboard",
    },
    { label: <button onClick={handleLogout}>Log Out</button>, key: "log_out" },
  ];

  return (
    <div className="avatar-dropdown" ref={dropdownRef}>
      <div
        className="dropdown-handler"
        onClick={handleDropdown}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {`${firstNameInitial}${lastNameInitial}`}
      </div>
      <Menu
        className={`dropdown-menu ${state ? "active" : "hide"}`}
        items={menuItems}
        onClick={closeDropdown}
      />
      <Modal
        title="Confirm Logout"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Yes"
        okButtonProps={{ className: "custom-ok-button" }}
        cancelText="No"
        cancelButtonProps={{ className: "cancel" }}
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </div>
  );
}
