import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";

import {
  DESTINATION_PAGE,
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  LISTINGS_DESTINATION,
  PRICING_PLAN_PAGE,
} from "settings/constant";

interface MainMenuProps {
  className?: string;
}

const menuItems = [
  {
    label: <NavLink to={HOME_PAGE}>Home</NavLink>,
    key: "menu-1",
  },
  {
    label: <NavLink to={LISTING_POSTS_PAGE}>Apartments</NavLink>,
    key: "menu-2",
  },
  {
    label: <NavLink to={DESTINATION_PAGE}>Destinations</NavLink>,
    key: "menu-3",
  },
];

const MainMenu: React.FC<MainMenuProps> = ({ className }) => {
  return <Menu className={className} items={menuItems} />;
};

export default MainMenu;
