import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "antd";
import "../../../App.css";

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  RESERVATION_TERMS_PAGE,
  HOUSE_RULES_PAGE,
  DESTINATION_PAGE,
} from "settings/constant";

const navigations = [
  {
    label: <NavLink to={`${HOME_PAGE}`}>Home</NavLink>,
    key: "hotels",
  },
  {
    label: <NavLink to={`${LISTING_POSTS_PAGE}`}>Apartments</NavLink>,
    key: "listing",
  },
  {
    label: <NavLink to={DESTINATION_PAGE}>Destinations</NavLink>,
    key: "destination",
  },
  {
    label: (
      <NavLink to={`${RESERVATION_TERMS_PAGE}`}>Terms & Conditions</NavLink>
    ),
    key: "privacy",
  },
  {
    label: <NavLink to={`${HOUSE_RULES_PAGE}`}>House Rules</NavLink>,
    key: "house-rules",
  },
  {
    label: (
      <NavLink to={`https://farathholdings.com`} target="_blank">
        Visit Website
      </NavLink>
    ),
    key: "website",
  },
];

const FooterMenu = () => {
  return <Menu items={navigations} />;
};

export default FooterMenu;
