// General Page Section
export const HOME_PAGE = "/";
export const AGENTS_PAGE = "/agents";

// Listing Single Page Section
export const LISTING_POSTS_PAGE = "/apartments";
export const LISTINGS_DESTINATION = "/destinations";
export const SINGLE_POST_PAGE = "/apartment";
export const LOCATION_PAGE = "/:selectedLocation";
export const CHECKOUT_PAGE = "/checkout";
export const DESTINATION_PAGE = "/destinations";
export const VERIFY_PAYMENT_PAGE = "/verify-payment";

// // Agent Profile Page Section
export const AGENT_PROFILE_PAGE = "/profile";
export const AGENT_PROFILE_FAVORITE = "/profile/favorite-post";
export const AGENT_ACCOUNT_SETTINGS_PAGE = "/account";
export const AGENT_PROFILE_EDIT_PAGE = "/profile/edit";
export const AGENT_PASSWORD_CHANGE_PAGE = "/profile/change-password";
// export const AGENT_PROFILE_DELETE = "/delete";

// Other Pages
export const PRICING_PLAN_PAGE = "/pricing-plan";
export const RESERVATION_TERMS_PAGE = "/reservation-terms";
export const HOUSE_RULES_PAGE = "/house-rules";

export const ADD_HOTEL_PAGE = "/add-hotel";

// Login / Registration Page
export const LOGIN_PAGE = "/sign-in";
export const REGISTRATION_PAGE = "/sign-up";
export const CHANGE_PASSWORD_PAGE = "/change-password";
export const FORGET_PASSWORD_PAGE = "/forget-password";
export const RESET_PASSWORD_PAGE = "/reset-password";
export const VERIFY_PAGE = `/verify`;
export const REGISTER_PAGE = "/registrationMessage";
