import React, { useState, ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  login,
  sendTokenEmail,
  getProfile,
  getListings,
  getListingByName,
  getFavorites,
  getuserBookings,
  getListing,
  searchListings,
} from "Api/authCalls";
import { AxiosError } from "axios";
import { IData } from "types/types";

// interface fetchBookings {
//   id: string;
//   room: string;
//   guest: string;
//   price: string;
//   discounted_total: string;
//   startDate: string;
//   endDate: string;
//   coupon: string;
// }

interface User {
  id: string;
  name: string;
  avatar: string;
  roles: string[];
  first_name: string;
  last_name: string;
  username: string;
  phone: string;
}

interface Favorites {
  id: number;
  name: string;
  avatar: string;
  roles: string[];
  first_name: string;
  last_name: string;
}

interface userBookings {
  id: string;
  name: string;
  avatar: string;
  roles: string[];
  first_name: string;
  last_name: string;
  listing?: Listing[];
  room: string;
  guest: string;
  price: string;
  discounted_total: string;
  startDate: string;
  endDate: string;
  coupon: string;
}
interface SingleListing {
  id: number;
  name: string;
  avatar: string;
  roles: string[];
  first_name: string;
  last_name: string;
}

interface Listing {
  id: string;
  name: string;
  slug: string;
  price: string;
  discounted_price: string;
  description: string;
  longitude: string;
  latitude: string;
  city: string;
  area: string;
  state: string;
  location_description: string;
  no_of_guests: number;
  no_of_rooms: number;
  rating: string;
  amenities: { icon: string; name: string }[];
  images: {
    id: string;
    image_url: string;
    is_active: boolean;
    created_on: string;
    updated_on: string;
    file: string;
    created_by: null | string;
    listing: string;
  }[];
  in_favorite: boolean;
}

interface AuthContextProps {
  loggedIn: boolean;
  user: User | null;
  listings: Listing[] | null;
  favorites: Favorites[] | null;
  userBookings: userBookings[] | null;
  singleListing: SingleListing[] | null;
  logOut: () => void;
  fetchOneListing: (name: string) => Promise<void>;
  fetchAllListings: (token: string) => Promise<void>;
  signIn: (
    email: string,
    password: string
  ) => Promise<{ success: boolean; msg: any; error?: any; status?: any }>;
  signUp: (
    userData: any
  ) => Promise<{ success: boolean; msg: any; error?: any }>;
  forgotPassword1: (email: string) => Promise<void>;
  fetchUserBookings: () => Promise<void>;
  fetchUserFavorites: () => Promise<void>;
  updateUser: (updatedUser: Partial<User>) => void;
  fetchUserProfile: () => Promise<void>;
  searchAllListings: (
    query: IData
  ) => Promise<{ success: boolean; msg: any; error?: any; status?: any }>;
}

interface SignInResponse {
  status: number;
  data: {
    msg: string;
  };
}
interface ErrorResponse {
  succeeded: boolean;
  msg: string;
  data: null;
  error: {
    non_field_errors: string[];
  };
}

export const AuthContext = React.createContext<AuthContextProps>({
  loggedIn: false,
  user: null,
  listings: null,
  favorites: null,
  userBookings: null,
  singleListing: null,
  logOut: () => {},
  fetchOneListing: async () => {},
  fetchAllListings: async () => {},
  signIn: async (userData: any) => {
    return { success: false, msg: "", error: "", data: "", status: "" };
  },
  signUp: async (userData: any) => {
    return { success: false, msg: "", error: "" };
  },
  forgotPassword1: async () => {},
  fetchUserBookings: async () => {},
  fetchUserFavorites: async () => {},
  fetchUserProfile: async () => {},
  updateUser: () => {},
  searchAllListings: async () => {
    return { success: false, msg: "", error: "", data: "", status: "" };
  },
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const [canReset, setCanReset] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [listings, setListings] = useState<Listing[] | null>(null);

  const [favorites, setFavorites] = useState<Favorites[] | null>(null);
  const [userBookings, setUserBookings] = useState<userBookings[] | null>(null);
  const [singleListing, setSingleListings] = useState<SingleListing[] | null>(
    null
  );
  const [token, setToken] = useState<string>(
    sessionStorage.getItem("accessToken") || ""
  );
  const [userID, setUserID] = useState<string>(
    sessionStorage.getItem("user_id") || ""
  );
  const [cities, setCities] = useState<string[]>([]);

  useEffect(() => {
    const accessToken = sessionStorage.getItem("accessToken");
    if (accessToken) {
      setLoggedIn(true);
    }
  }, []);

  useEffect(() => {
    if (token && userID) {
      fetchUserProfile();
    }
  }, [token, userID]);

  useEffect(() => {
    fetchAllListings();
  }, [token]);

  useEffect(() => {
    if (token) {
      setLoggedIn(true);
      fetchUserFavorites();
      fetchUserBookings();
    }
  }, [token]);

  const signIn = async (
    email: string,
    password: string
  ): Promise<{ success: boolean; msg: any; error?: any }> => {
    try {
      const response = await login(email, password); // Assuming login function makes an API call
      const accessToken = response.data?.data?.access;
      const userId = response.data?.data?.user_id;
      if (accessToken) {
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("userId", userId);
        setToken(accessToken);
        setUserID(userId);
        setLoggedIn(true);
        navigate("/", { replace: true });
        return { success: true, msg: "Logged in successfully" };
      } else {
        console.error("Invalid response format (access missing)");
        console.error(response);
        return { success: false, error: response, msg: null };
      }
    } catch (error) {
      const err = error as AxiosError<ErrorResponse>;
      return { success: false, error: err, msg: null };
    }
  };

  const updateUser = (updatedUser: any) => {
    setUser(updatedUser);
  };
  const signUp = async (
    userData: any
  ): Promise<{ success: boolean; msg: any; error?: any }> => {
    try {
      // Call your registerUser API here
      const response = await signUp(userData);
      if (response.success) {
        return { success: true, msg: response.msg };
      } else {
        return { success: false, error: response.msg, msg: null };
      }
    } catch (error) {
      console.error("Sign up failed:", error);
      return { success: false, error: "Sign up failed", msg: null };
    }
  };

  const fetchUserProfile = async () => {
    try {
      // Call getProfile API to fetch user profile
      const response = await getProfile(userID, token);
      const userProfile = response?.data?.data;
      localStorage.setItem("profile", JSON.stringify(userProfile));
      setUser(userProfile);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchAllListings = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    try {
      // Call getListings API to fetch all Listings
      const response = accessToken
        ? await getListing(accessToken)
        : await getListings();
      const listings = response?.data?.data.results;

      setListings(listings);
      const areas = new Set<string>(
        listings.map((listing: Listing) => listing.area)
      );
      const states = new Set<string>(
        listings.map((listing: Listing) => listing.state)
      );
      const areaArray = Array.from(areas);
      const stateArray = Array.from(states);
      sessionStorage.setItem("cities", JSON.stringify(areaArray));
      sessionStorage.setItem("states", JSON.stringify(stateArray));
    } catch (error) {
      console.error("Error fetching listings:", error);
    }
  };

  const fetchUserFavorites = async () => {
    try {
      // Call getListings API to fetch all Listings
      const response = await getFavorites(token);
      const favorites = response?.data?.data.results;
      setFavorites(favorites);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchUserBookings = async () => {
    try {
      const response = await getuserBookings(token);
      const bookings = response?.data?.data.results;
      const latestBookingId = bookings.length > 0 ? bookings[0].id : null;
      if (latestBookingId) {
        sessionStorage.setItem("latestBookingId", latestBookingId);
      }
      setUserBookings(bookings);
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const fetchOneListing = async (slug: string) => {
    try {
      const response = await getListingByName(slug);
      const data = response?.data?.data;

      // Update the state with the fetched listing data
      setSingleListings(data);
    } catch (error) {
      console.error("Error fetching listing:", error);
    }
  };

  const forgotPassword1 = async (email: string) => {
    try {
      const response = await sendTokenEmail(email);
      setCanReset(true);
    } catch (error) {}
  };

  const logOut = () => {
    setUser(null);
    setLoggedIn(false);
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("accessToken");
    sessionStorage.clear();
  };

  const searchAllListings = async (query: IData) => {
    try {
      const response = await searchListings(query);
      return response?.data?.data?.results;
    } catch (error) {
      return error;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loggedIn,
        logOut,
        signIn,
        favorites,
        userBookings,
        signUp,
        forgotPassword1,
        fetchOneListing,
        fetchUserFavorites,
        user,
        listings,
        fetchUserBookings,
        singleListing,
        fetchAllListings,
        updateUser,
        fetchUserProfile,
        searchAllListings,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
