import axios, { AxiosResponse } from "axios";

export const baseURL = "https://api.farathholdings.com/api";

export const resetPassword = async (resetData: {
  token: string;
  email: string;
  new_password: string;
  confirm_new_password: string;
}): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/reset-password`, resetData);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// Function for sending token email
export const sendTokenEmail = async (email: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/send-reset-token`, { email });
    return response;
  } catch (error: any) {
    console.log("error", error);
    return error.response;
  }
};
