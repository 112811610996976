import React, { useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, Modal } from 'antd';
import { AuthContext } from 'context/AuthProvider';
import { useNavigate } from 'react-router-dom';

import {
  HOME_PAGE,
  LISTING_POSTS_PAGE,
  PRICING_PLAN_PAGE,
  AGENT_PROFILE_PAGE,
  DESTINATION_PAGE,
} from 'settings/constant';

interface NavigationItem {
  label: JSX.Element | string | null; // Allow null as a type for the label
  key: string;
}

interface MobileMenuProps {
  className: string;
  onMenuClick: () => void; // Add onMenuClick prop
}

const MobileMenu: React.FC<MobileMenuProps> = ({ className, onMenuClick }) => {
  // auth context
  const { loggedIn, logOut } = useContext(AuthContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  const handleOk = () => {
    logOut();
    navigate('/', { replace: true });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  function handleLogouts() {
    setIsModalVisible(true);
  }

  const navigations: NavigationItem[] = [
    {
      label: (
        <div onClick={onMenuClick}>
          <NavLink to={HOME_PAGE}>Home</NavLink>
        </div>
      ),
      key: 'home',
    },
    {
      label: (
        <div onClick={onMenuClick}>
          <NavLink to={LISTING_POSTS_PAGE}>Apartments</NavLink>
        </div>
      ),
      key: 'apartments',
    },
    {
      label: <NavLink to={DESTINATION_PAGE}>Destination</NavLink>,
      key: 'destination',
    },
    {
      label: <NavLink to={AGENT_PROFILE_PAGE}>Dashboard</NavLink>,
      key: 'dashboard',
    },
    // },{
    //   label: (
    //     <div onClick={onMenuClick}>
    //       <NavLink to={LISTING_POSTS_PAGE}>Destinations</NavLink>
    //     </div>
    //   ),
    //   key: 'destinations',
    // },
    {
      label: loggedIn ? (
        <div
          onClick={() => {
            logOut();
            onMenuClick();
          }}
        >
          <button>Log Out</button>
        </div>
      ) : null, // Set label to null if not logged in
      key: 'logout',
    },
  ];

  return (
    <>
      <Menu className={className} items={navigations} />{' '}
      <Modal
        title='Confirm Logout'
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText='Yes'
        okButtonProps={{ className: 'custom-ok-button' }}
        cancelText='No'
        cancelButtonProps={{ className: 'cancel' }}
      >
        <p>Are you sure you want to log out?</p>
      </Modal>
    </>
  );
};

export default MobileMenu;
