import axios, { AxiosResponse } from "axios";
import { baseURL } from "../containers/Auth/app.use";
import { access } from "fs";
import { encodeQuery } from "library/helpers/utils";
import { IData } from "types/types";
// import ChangePassWord from "../containers/Agent/AccountSettings/ChangePassWordForm";

// Function to verify registration
export const verifyRegistration = async (
  verificationCode: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${baseURL}/verify/${verificationCode}`);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getProfile = async (
  userId: string,
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    const response = await axios.get(
      `${baseURL}/get-profile/${userId}`,
      config
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getListings = async (
  accessToken?: string
): Promise<AxiosResponse> => {
  try {
    if (accessToken) {
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };
      const response = await axios.get(`${baseURL}/listings`, config);
      return response;
    } else {
      const response = await axios.get(`${baseURL}/listings`);
      return response;
    }
  } catch (error: any) {
    return error.response;
  }
};

// Function for login
export const login = async (
  username: string,
  password: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/login/token`, {
      username,
      password,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// Function for refreshing login
export const refreshLogin = async (
  refreshToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/login/token/refresh/`, {
      refresh: refreshToken,
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// Function for user registration
export const registerUser = async (
  userData: FormData
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/register`, userData);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// Function for resetting password
export const resetPassword = async (resetData: {
  token: string;
  email: string;
  newPassword: string;
  confirmNewPassword: string;
}): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/reset-password`, resetData);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// Function for sending token email
export const sendTokenEmail = async (email: string): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/send-reset-token`, { email });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getListing = async (
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${baseURL}/listings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getListingByName = async (slug: string) => {
  try {
    const url = `${baseURL}/listings/${slug}`;
    const response = await axios.get(url);

    // Return the Axios response object
    return response;
  } catch (error: any) {
    // Handle the error, and rethrow it if necessary
    return error.response;
  }
};

//function for change password
export const ChangePassword = async (
  current_password: string,
  new_password: string,
  confirm_new_password: string,
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${baseURL}/change-password`,
      {
        current_password,
        new_password,
        confirm_new_password,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// update users profile
export const updateUserProfile = async (
  userId: string,
  accessToken: string,
  userData: FormData
): Promise<AxiosResponse> => {
  try {
    const response = await axios.put(
      `${baseURL}/update-profile/${userId}`,
      userData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data", // Set content type to multipart/form-data for FormData
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
// create Reviews
export const createReview = async (reviewData: {
  listing: string;
  title: string;
  description: string;
}): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(`${baseURL}/reviews/create`, reviewData);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// get Reviews
// export const getReviews = async (
//   accessToken: string
// ): Promise<AxiosResponse> => {
//   try {
//     const config = {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     };
//     const response = await axios.get(`${baseURL}/reviews`, config);
//     return response;
//   } catch (error: any) {
//     throw error;
//   }
// };

export const getReviews = async (
  accessToken: string,
  name: string
): Promise<AxiosResponse> => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    // Constructing the URL with the room name as a query parameter
    const url = `${baseURL}/reviews?listing__name=${name}`;
    const response = await axios.get(url, config);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const addToFavorites = async (
  listing: string,
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${baseURL}/add-to-favorite`,
      {
        listing,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const removeFromFavorites = async (
  listing: string,
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${baseURL}/remove-from-favorite`,
      {
        listing,
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};
export const toggleFavoriteStatus = async (
  listing: string,
  accessToken: string,
  isFavorite: boolean
): Promise<AxiosResponse> => {
  if (isFavorite) {
    // If the listing is already a favorite, remove it from favorites
    return await removeFromFavorites(listing, accessToken);
  } else {
    // If the listing is not a favorite, add it to favorites
    return await addToFavorites(listing, accessToken);
  }
};

export const getFavorites = async (
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${baseURL}/user-listings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getuserBookings = async (
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${baseURL}/bookings`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const createBookings = async (
  listing: string,
  total: number,
  price: number,
  discounted_total: number,
  reference: string,
  checkin: string,
  checkout: string,
  coupon: string,
  accessToken: string,
  guests: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.post(
      `${baseURL}/booking/create`,
      {
        listing,
        total,
        price,
        discounted_total,
        reference,
        checkin,
        checkout,
        coupon,
        guests,
        redirect_url: window.location.origin + "/verify-payment",
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (error: any) {
    return error.response;
  }
};

//single bookings
export const getSingleBookings = async (
  latestBookingId: string,
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const response = await axios.get(`${baseURL}/booking/${latestBookingId}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const getPaymentVerification = async (
  gatewayReference: string
  // accessToken: string
): Promise<AxiosResponse> => {
  const token = sessionStorage.getItem("accessToken");
  try {
    const response = await axios.post(
      `${baseURL}/verify-payment`,
      {
        gateway_reference: gatewayReference,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // const response = await axios.post(`${baseURL}/verify-payment`, {
    //   gateway_reference: gatewayReference,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // });
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// filter by location
export const getListingByLocation = async (
  // place_of_location: string,
  // area: string,
  city: string
  // address: string
) => {
  try {
    const url = `${baseURL}/listings?place_of_location=${city}`;
    const response = await axios.get(url);
    // return response.data.data.results;
    // Return the Axios response object
    return response;
  } catch (error: any) {
    // Handle the error, and rethrow it if necessary
    return error.response;
  }
};

export const fetchLocations = async () => {
  try {
    const url = `${baseURL}/locations`;
    const response = await axios.get(url);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

// {{base_url}}/listings??place_of_location&state&price&amenities=test,red&checkin=2024-07-20&checkout=2024-07-21&no_of_guests=4
export const searchListings = async (query: IData) => {
  const queries = encodeQuery({ ...query });
  try {
    const url = `${baseURL}/listings?${queries}`;
    const response = await axios.get(url);
    return response;
  } catch (error: any) {
    return error.response;
  }
};

export const checkListing = async (
  listing: string,
  accessToken: string
): Promise<AxiosResponse> => {
  try {
    const url = `${baseURL}/check-review`;
    const response = await axios.post(
      url,
      { listing },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response;
  } catch (err: any) {
    return err.response;
  }
};
