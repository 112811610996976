import React, { useEffect, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { NavbarSearchWrapper } from "./Header.style";
import { AutoComplete, Input } from "antd";
import { useNavigate } from "react-router-dom";

export default function NavbarSearch(props: any) {
  const [options, setOptions] = useState<any>([]);
  const navigate = useNavigate();

  const handleSearch = (value: any) => {
    setOptions(!value ? [] : [value, value + value, value + value + value]);
    console.log("search", value);
  };

  const handleSelect = (value: any) => {
    sessionStorage.setItem("selectedLocation", value);
    navigate(`/${value}`);
  };

  return (
    <NavbarSearchWrapper className="navbar_search">
      <AutoComplete
        style={{ width: 300, background: "transparent" }}
        onSearch={handleSearch}
        options={props?.locations?.map((option: any) => ({
          value: option.place_of_location,
        }))}
        onSelect={handleSelect}
        // onClick={handleClick}
      >
        <Input suffix={<FiSearch />} placeholder="Search location" />
      </AutoComplete>
    </NavbarSearchWrapper>
  );
}
