import React, { useState, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sticky from "react-stickynode";
import { IoIosClose } from "react-icons/io";
import { Button, Drawer } from "antd";
import Logo from "components/UI/Logo/Logo";
import Navbar from "components/Navbar/Navbar";
import { AuthContext } from "context/AuthProvider";
import { LayoutContext } from "context/LayoutProvider";
import useWindowSize from "library/hooks/useWindowSize";
import AuthMenu from "./AuthMenu";
import MainMenu from "./MainMenu";
import MobileMenu from "./MobileMenu";
import ProfileMenu from "./ProfileMenu";
import NavbarSearch from "./NavbarSearch";
import HeaderWrapper, {
  MobileNavbar,
  CloseDrawer,
  AvatarWrapper,
  AvatarImage,
  AvatarInfo,
  LogoArea,
} from "./Header.style";
import { fetchLocations } from "Api/authCalls";

// http://s3.amazonaws.com/redqteam.com/isomorphic-reloaded-image/profilepic.png
const avatarImg = ``;
const LogoIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="100"
    viewBox="0 0 300.000000 307.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,307.000000) scale(0.100000,-0.100000)"
      fill="#fff"
      stroke="none"
    >
      <path
        d="M1274 2914 c-164 -81 -399 -195 -521 -255 l-223 -108 0 -711 0 -710
160 0 160 0 2 266 3 265 358 182 358 182 2 158 c1 86 0 156 -3 154 -3 -1 -164
-82 -357 -180 -194 -97 -353 -182 -353 -187 0 -13 23 -13 37 1 6 6 17 12 24
13 12 2 348 168 415 204 10 6 51 25 89 43 39 18 73 37 76 42 4 5 15 7 25 5 17
-3 19 -14 20 -103 0 -55 -2 -109 -6 -120 -7 -19 -116 -82 -132 -77 -5 1 -8 -3
-8 -8 0 -6 -9 -10 -20 -10 -11 0 -20 -4 -20 -8 0 -5 -30 -23 -67 -40 -38 -17
-79 -38 -93 -46 -14 -8 -86 -45 -160 -81 -170 -83 -194 -99 -208 -129 -7 -16
-11 -107 -12 -257 0 -128 -1 -234 -3 -235 -1 -1 -58 -4 -127 -5 l-125 -3 -3
682 -2 683 26 24 c15 14 32 25 39 25 7 0 97 42 201 94 182 90 217 106 230 105
4 0 10 4 13 9 3 5 50 30 103 56 54 26 100 51 103 57 4 5 11 7 16 4 5 -4 9 -1
9 6 0 7 3 10 6 6 3 -3 40 12 82 34 43 21 81 39 86 39 4 0 28 13 52 30 l44 29
6 -23 c3 -13 4 -85 2 -159 l-3 -135 235 -144 c129 -78 268 -163 308 -188 l73
-45 2 -453 2 -452 -527 0 -528 0 0 -155 0 -155 665 0 665 0 0 713 0 712 -444
253 c-244 138 -446 252 -448 252 -3 0 -139 -66 -304 -146z m466 18 c52 -30
115 -66 140 -80 25 -14 77 -43 115 -66 109 -63 114 -66 258 -146 74 -41 133
-78 130 -82 -2 -5 2 -8 10 -8 37 0 37 -13 37 -700 0 -371 -2 -675 -4 -678 -3
-2 -282 -5 -621 -5 -538 -2 -617 0 -625 13 -15 23 -13 207 3 217 6 4 235 7
507 6 493 -2 494 -2 517 19 l23 21 0 450 0 450 -38 36 c-21 20 -86 63 -143 95
-57 32 -107 64 -111 70 -4 6 -8 7 -8 3 0 -5 -12 1 -26 12 -15 12 -32 21 -39
21 -7 0 -18 7 -25 15 -7 9 -43 32 -79 52 -36 20 -68 40 -71 44 -3 4 -24 17
-47 29 -48 24 -52 39 -52 180 1 118 -1 118 149 32z"
      />
      <path
        d="M1445 2683 c-44 -24 -97 -51 -117 -60 -21 -9 -38 -20 -38 -25 0 -4
-6 -8 -14 -8 -7 0 -51 -20 -97 -45 -46 -25 -111 -59 -144 -76 -228 -114 -216
-97 -213 -309 2 -118 7 -173 16 -182 9 -9 12 26 12 178 l0 189 113 58 c121 61
356 183 505 262 50 27 92 52 92 56 0 16 -39 3 -115 -38z"
      />
      <path
        d="M589 938 c-170 -447 -176 -459 -221 -474 -40 -12 -14 -20 72 -20 88
-1 115 8 65 20 -42 11 -47 36 -24 111 l20 66 82 -3 81 -3 22 -70 c20 -64 21
-72 7 -87 -9 -10 -24 -18 -35 -18 -10 0 -18 -4 -18 -8 0 -4 115 -8 255 -8 140
-1 255 3 255 8 0 4 -11 8 -24 8 -47 0 -56 22 -56 136 0 115 1 116 65 89 40
-16 50 -37 67 -136 17 -108 9 -103 207 -105 94 -1 171 1 171 6 0 4 -15 11 -32
16 -41 12 -46 37 -22 116 l17 59 81 -3 81 -3 23 -64 c26 -74 22 -100 -19 -108
-62 -12 -24 -19 101 -19 71 0 130 3 130 6 0 3 -15 12 -33 20 -30 14 -39 33
-129 254 -53 131 -102 244 -107 249 -17 17 -25 2 -80 -138 -141 -363 -134
-349 -164 -363 -60 -29 -85 -5 -122 118 -20 66 -47 98 -92 106 -38 7 -35 24 5
24 39 0 90 35 108 74 34 70 -8 142 -97 166 -24 7 -95 9 -184 5 -80 -3 -150 -5
-155 -5 -6 0 -8 -4 -5 -10 3 -5 17 -10 30 -10 14 0 29 -8 35 -19 6 -12 10
-102 10 -225 0 -224 -3 -236 -58 -236 -60 0 -60 0 -195 335 -43 106 -79 181
-87 183 -10 1 -21 -13 -31 -40z m597 -29 c31 -29 38 -69 21 -110 -19 -46 -39
-61 -92 -67 l-45 -5 0 101 c0 71 4 102 13 106 23 10 80 -3 103 -25z m-162 -6
c6 -21 9 -156 7 -300 -1 -84 -4 -103 -16 -103 -13 0 -15 30 -15 210 0 123 4
210 9 210 5 0 12 -8 15 -17z m266 -39 c10 -27 -4 -69 -26 -78 -9 -3 -13 12
-16 59 -3 58 -2 62 15 53 10 -5 22 -20 27 -34z m-653 -6 c9 -18 18 -37 19 -43
2 -11 78 -204 99 -250 19 -43 22 -67 8 -72 -7 -2 -18 19 -28 54 -9 32 -25 72
-35 88 -10 17 -21 46 -25 65 -4 19 -20 62 -36 94 -39 79 -41 136 -2 64z m1054
-26 c7 -21 22 -59 34 -87 12 -27 28 -66 35 -85 7 -19 21 -54 31 -78 11 -23 20
-52 22 -64 3 -32 -21 -34 -28 -1 -10 51 -27 98 -45 123 -9 14 -20 42 -24 62
-4 20 -20 63 -36 95 -33 65 -37 85 -15 77 8 -3 19 -22 26 -42z m-1074 -82 l31
-80 -68 0 -68 0 31 80 c17 44 33 80 36 80 3 0 20 -36 38 -80z m1039 8 c16 -40
31 -76 32 -80 2 -5 -26 -8 -62 -8 -36 0 -66 3 -66 7 0 13 55 153 61 153 3 0
19 -33 35 -72z m-380 -205 c13 -52 11 -65 -10 -57 -8 3 -17 24 -20 47 -4 23
-10 53 -13 67 -11 49 30 -6 43 -57z"
      />
      <path
        d="M1856 901 c-8 -74 4 -99 27 -55 29 56 62 77 126 82 l59 4 7 -93 c4
-52 5 -150 3 -217 -3 -116 -5 -125 -27 -143 -13 -10 -34 -19 -47 -19 -13 0
-24 -4 -24 -8 0 -4 70 -8 155 -8 153 1 193 7 115 20 -22 3 -43 14 -48 24 -13
24 -24 297 -16 377 l7 65 44 0 c61 0 106 -22 132 -64 13 -20 27 -36 32 -36 10
0 12 48 3 100 l-6 35 -268 1 -268 0 -6 -65z m284 12 c7 -209 3 -417 -7 -420
-9 -3 -13 47 -15 199 -2 112 -1 209 1 216 3 13 21 17 21 5z"
      />
      <path
        d="M2420 958 c0 -4 8 -8 19 -8 42 0 46 -23 45 -229 -1 -232 -3 -247 -44
-256 -61 -13 -27 -21 95 -21 69 0 125 4 125 8 0 4 -9 8 -19 8 -41 0 -51 25
-51 129 l0 98 47 7 c26 3 75 3 110 0 l63 -7 -1 -76 c-2 -115 -8 -134 -46 -146
-18 -5 -33 -12 -33 -15 0 -4 59 -7 130 -7 72 0 130 2 130 6 0 4 -15 11 -32 16
l-33 10 -3 222 c-3 241 0 253 54 253 13 0 24 4 24 8 0 4 -61 8 -135 8 -74 1
-135 -3 -135 -8 0 -4 11 -8 24 -8 46 0 56 -23 56 -126 l0 -94 -110 0 -110 0 0
84 c0 105 10 136 45 136 14 0 25 4 25 9 0 5 -54 8 -120 8 -66 -1 -120 -5 -120
-9z m460 -246 c0 -182 -7 -238 -27 -219 -4 5 -6 44 -4 88 2 48 -2 93 -10 110
-9 23 -9 33 0 42 6 6 11 45 11 92 0 45 3 85 6 88 19 19 24 -22 24 -201z m-330
115 c0 -50 5 -88 12 -95 9 -9 9 -18 0 -37 -7 -14 -12 -64 -12 -110 0 -72 -3
-85 -16 -85 -14 0 -16 8 -10 46 3 26 6 118 6 205 0 99 4 159 10 159 6 0 10
-34 10 -83z"
      />
      <path
        d="M0 950 c0 -5 13 -10 30 -10 19 0 33 -7 40 -19 6 -12 10 -100 10 -218
0 -217 -6 -243 -56 -243 -13 0 -24 -4 -24 -9 0 -4 63 -8 140 -8 77 0 140 4
140 9 0 4 -13 8 -29 8 -53 0 -61 19 -61 139 l0 108 40 -7 c49 -9 67 -23 75
-61 13 -58 25 -27 27 66 0 52 1 100 2 107 1 6 -5 13 -11 16 -8 2 -13 -5 -13
-17 0 -31 -41 -68 -83 -76 l-38 -6 3 98 3 98 55 3 c88 5 117 -5 166 -55 25
-25 47 -44 49 -41 3 2 -1 32 -7 66 l-12 62 -223 0 c-142 0 -223 -4 -223 -10z
m152 -129 c5 -158 -5 -324 -19 -329 -10 -3 -13 44 -13 212 0 181 2 216 14 216
12 0 15 -21 18 -99z"
      />
      <path
        d="M510 215 l0 -115 26 0 c24 0 25 2 23 48 l-3 47 57 3 57 3 0 -51 c0
-45 2 -50 23 -50 22 0 22 2 20 112 -1 106 -3 113 -22 116 -19 3 -21 -2 -21
-47 l0 -51 -57 0 -58 0 3 50 c4 48 3 50 -22 50 l-26 0 0 -115z"
      />
      <path
        d="M843 314 c-63 -31 -81 -128 -32 -180 53 -56 152 -52 189 9 39 63 16
147 -45 173 -44 18 -72 17 -112 -2z m109 -41 c26 -23 25 -96 -2 -123 -26 -26
-74 -26 -100 0 -24 24 -27 86 -4 118 19 27 78 30 106 5z"
      />
      <path
        d="M1100 216 l0 -116 80 0 c64 0 80 3 80 15 0 12 -13 15 -61 15 l-61 0
3 98 c4 94 3 97 -18 100 -23 3 -23 2 -23 -112z"
      />
      <path
        d="M1330 214 l0 -114 63 0 c102 0 156 48 143 129 -10 65 -41 84 -158 94
l-48 5 0 -114z m134 55 c20 -16 26 -29 26 -58 0 -47 -24 -71 -79 -78 l-41 -6
0 82 0 81 34 0 c19 0 45 -9 60 -21z"
      />
      <path
        d="M1609 328 c-1 -2 -2 -54 -3 -115 -1 -111 -1 -113 21 -113 23 0 23 2
23 115 0 108 -1 115 -20 115 -11 0 -20 -1 -21 -2z"
      />
      <path
        d="M1740 328 c0 -2 -1 -54 -3 -116 -2 -111 -2 -112 21 -112 23 0 23 2
20 80 -2 44 -1 80 2 79 3 0 31 -36 62 -80 46 -62 63 -79 83 -79 l25 0 0 115 0
115 -26 0 -27 0 6 -85 c3 -47 5 -85 3 -85 -2 0 -31 38 -65 85 -41 56 -69 85
-82 85 -10 0 -19 -1 -19 -2z"
      />
      <path
        d="M2114 316 c-71 -31 -98 -123 -53 -179 26 -34 87 -51 141 -40 l41 8 2
58 1 57 -54 0 c-46 0 -53 -3 -50 -17 3 -13 12 -17 31 -16 24 2 27 -2 27 -28 0
-26 -3 -29 -33 -29 -67 0 -103 59 -73 118 18 36 33 42 97 42 35 0 49 4 49 14
0 28 -72 35 -126 12z"
      />
      <path
        d="M2346 309 c-31 -24 -35 -68 -8 -92 9 -8 34 -18 54 -22 49 -9 64 -27
41 -50 -13 -14 -28 -16 -71 -12 -45 5 -53 3 -50 -10 7 -38 122 -39 159 -2 23
23 25 62 3 80 -9 8 -37 21 -63 29 -34 11 -46 20 -46 35 0 18 7 20 58 20 46 0
57 3 57 16 0 10 -11 19 -31 23 -52 10 -75 7 -103 -15z"
      />
    </g>
  </svg>
);

const getInitials = (
  firstName: string | undefined,
  lastName: string | undefined
): string => {
  const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
  const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
  return `${firstInitial}${lastInitial}`;
};
export default function Header() {
  let location = useLocation();
  const [{ searchVisibility }] = useContext(LayoutContext);
  const { loggedIn, user } = useContext(AuthContext);
  const { width } = useWindowSize();
  const [state, setState] = useState(false);
  const sidebarHandler = () => {
    setState(!state);
  };

  const [locations, setLocations] = useState([]);

  const getLocations = async () => {
    const response = await fetchLocations();
    if (response.data.length > 0) {
      setLocations(response.data);
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  const headerType = location.pathname === "/" ? "transparent" : "default";

  const initials = getInitials(user?.first_name, user?.last_name);

  return (
    <HeaderWrapper>
      <Sticky
        top={headerType === "transparent" ? -1 : 0}
        innerZ={10001}
        activeClass="isHeaderSticky"
      >
        {width && width > 991 ? (
          <Navbar
            logo={
              <>
                {headerType === "transparent" && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/Farath_Holdings-rbg.png"
                  title="Farath Holdings."
                />
              </>
            }
            navMenu={<MainMenu />}
            authMenu={<AuthMenu />}
            isLogin={loggedIn}
            avatar={<Logo src={avatarImg} />}
            profileMenu={<ProfileMenu avatar={<Logo src={avatarImg} />} />}
            headerType={headerType}
            searchComponent={<NavbarSearch locations={locations} />}
            location={location}
            searchVisibility={searchVisibility}
          />
        ) : (
          <MobileNavbar className={headerType}>
            <LogoArea>
              <>
                {headerType === "transparent" && <LogoIcon />}
                <Logo
                  withLink
                  linkTo="/"
                  src="/images/Farath_Holdings-rbg.png"
                  title="Farath Holdings."
                />
              </>
              <NavbarSearch locations={locations} />
            </LogoArea>
            <Button
              className={`hamburg-btn ${state ? "active" : ""}`}
              onClick={sidebarHandler}
            >
              <span />
              <span />
              <span />
            </Button>
            <Drawer
              placement="right"
              closable={false}
              onClose={sidebarHandler}
              width="285px"
              className="mobile-header"
              open={state}
            >
              <CloseDrawer>
                <button onClick={sidebarHandler}>
                  <IoIosClose />
                </button>
              </CloseDrawer>
              {loggedIn ? (
                <AvatarWrapper>
                  <AvatarImage
                    style={{
                      backgroundColor: "#eba14c",
                      color: "white",
                      padding: "15px",
                      borderRadius: "50%",
                      fontSize: "20pX",
                    }}
                  >
                    {initials}
                  </AvatarImage>
                </AvatarWrapper>
              ) : (
                <AuthMenu className="auth-menu" />
              )}
              <MobileMenu className="main-menu" onMenuClick={sidebarHandler} />
            </Drawer>
          </MobileNavbar>
        )}
      </Sticky>
    </HeaderWrapper>
  );
}
