export const getRandomGradient = () => {
  const gradients = ["gradient1", "gradient2", "gradient3"];
  const randomIndex = Math.floor(Math.random() * gradients.length);
  return gradients[randomIndex];
};

export const encodeQuery = (queryObj) => {
  const encodedQuery = Object.entries(queryObj).map(([key, value]) => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(value.toString());
  });
  return encodedQuery.join("&");
};
